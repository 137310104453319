"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.query = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _TextInput = require("@oberoninternal/travelbase-ds/components/form/TextInput");
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _grid = require("@rebass/grid");
var _client = require("@apollo/client");
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var Yup = _interopRequireWildcard(require("yup"));
var _messages = _interopRequireDefault(require("../constants/messages"));
var _graphql = require("../generated/graphql");
var _auth = require("../utils/auth");
var _Formed = _interopRequireDefault(require("./Formed"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
var _CheckEmailMessage = _interopRequireDefault(require("./CheckEmailMessage"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _router = require("next/router");
const _excluded = ["title", "description", "helperText", "landingUrl", "brandName", "className"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const initialValues = {
  email: ''
};
const query = exports.query = (0, _client.gql)`
    query Me {
        viewer {
            id
        }
    }
    mutation SendAuthenticationEmail($input: SendAuthenticationEmailInput!) {
        sendAuthenticationEmail(input: $input) {
            success
        }
    }
`;
const LoginContent = _ref => {
  let {
      title,
      description,
      helperText,
      landingUrl,
      brandName,
      className
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const {
    formatMessage
  } = (0, _reactIntl.useIntl)();
  const {
    0: tryAgain,
    1: setTryAgain
  } = (0, _react.useState)(false);
  const [mutate, {
    loading,
    called,
    data
  }] = (0, _graphql.useSendAuthenticationEmailMutation)();
  const validationSchema = (0, _react.useMemo)(() => Yup.object().shape({
    email: Yup.string().email(formatMessage(_messages.default.emailError)).required(formatMessage(_messages.default.fieldError))
  }), [formatMessage]);
  const router = (0, _router.useRouter)();
  const onRegister = props.onToggle ?? function onLogin() {
    router.push('/register');
  };
  return __jsx(Content, {
    className: className
  }, __jsx(_Formed.default, {
    initialValues: initialValues,
    handleSubmit: async values => {
      if (tryAgain) {
        setTryAgain(false);
      }
      // remove current tokens from localStorage otherwise we could get a 401
      (0, _auth.logout)();
      await mutate({
        variables: {
          input: _objectSpread(_objectSpread({}, values), {}, {
            landingUrl
          })
        }
      });
    },
    validationSchema: validationSchema,
    skipPrompt: true
  }, () => {
    if (called && data?.sendAuthenticationEmail.success && !tryAgain) {
      return __jsx(_CheckEmailMessage.default, {
        onTryAgain: () => setTryAgain(true),
        brandName: brandName
      });
    }
    return __jsx(_react.default.Fragment, null, __jsx(_Title.default, null, title), description && __jsx(Description, null, description), __jsx(StyledFieldSet, {
      id: "email"
    }, __jsx(FieldSetLabelWrapper, null, __jsx(FieldSetLabel, null, __jsx(_reactIntl.FormattedMessage, {
      id: "EMJa84",
      defaultMessage: [{
        "type": 0,
        "value": "E-mailadres"
      }]
    }))), __jsx(EmailField, {
      "data-cy": "loginEmail",
      name: "email",
      type: "email",
      placeholder: formatMessage({
        "id": "4jR+rB",
        "defaultMessage": [{
          "type": 0,
          "value": "voorbeeld@mail.com"
        }]
      })
    })), __jsx(LoginButton, {
      "data-cy": "login",
      submitting: loading,
      size: "large",
      type: "submit"
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "KpwJ0D",
      defaultMessage: [{
        "type": 0,
        "value": "Verstuur"
      }]
    })), helperText && __jsx(NeutralBody, {
      variant: "tiny"
    }, helperText), __jsx(CreateAccountWrapper, null, __jsx(StyledBody, null, __jsx(_reactIntl.FormattedMessage, {
      id: "eKJ/3K",
      defaultMessage: [{
        "type": 0,
        "value": "Geen account?"
      }]
    })), __jsx(StyledLink, {
      href: "#",
      onClick: e => {
        e.preventDefault();
        onRegister();
      }
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "C/R8du",
      defaultMessage: [{
        "type": 0,
        "value": "Account aanmaken"
      }]
    }))));
  }));
};
var _default = exports.default = LoginContent;
const Content = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "LoginContent__Content",
  componentId: "sc-1w7wyb0-0"
})(["max-width:44.8rem;"]);
const LoginButton = (0, _styledComponents.default)(_Button.default).withConfig({
  displayName: "LoginContent__LoginButton",
  componentId: "sc-1w7wyb0-1"
})(["width:100%;margin-bottom:", ";margin-top:2.4rem;"], ({
  theme
}) => theme.spacing['40_Standard']);
const NeutralBody = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "LoginContent__NeutralBody",
  componentId: "sc-1w7wyb0-2"
})(["color:", ";text-align:center;"], ({
  theme
}) => theme.colors.neutral['50']);
const Description = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "LoginContent__Description",
  componentId: "sc-1w7wyb0-3"
})(["margin-top:", ";margin-bottom:", ";padding-bottom:", ";"], ({
  theme
}) => theme.spacing['30_Small'], ({
  theme
}) => theme.spacing['40_Standard'], ({
  theme
}) => theme.spacing['30_Small']);
const EmailField = (0, _styledComponents.default)(_TextInput.TextInputField).withConfig({
  displayName: "LoginContent__EmailField",
  componentId: "sc-1w7wyb0-4"
})(["min-height:", ";@media screen and (min-width:", "){min-height:", ";}::placeholder{color:", ";}"], ({
  theme
}) => theme.spacing['60_Large'], ({
  theme
}) => theme.mediaQueries.m, ({
  theme
}) => theme.spacing['70_XLarge'], ({
  theme
}) => theme.colors.neutral['40']);
const StyledFieldSet = _styledComponents.default.div.withConfig({
  displayName: "LoginContent__StyledFieldSet",
  componentId: "sc-1w7wyb0-5"
})(["margin-bottom:2rem;"]);
const FieldSetLabelWrapper = _styledComponents.default.div.withConfig({
  displayName: "LoginContent__FieldSetLabelWrapper",
  componentId: "sc-1w7wyb0-6"
})(["margin-bottom:1.6rem;"]);
const FieldSetLabel = _styledComponents.default.label.withConfig({
  displayName: "LoginContent__FieldSetLabel",
  componentId: "sc-1w7wyb0-7"
})(["", ";font-weight:400;line-height:1.6rem;color:", ";"], ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.body.small), ({
  theme
}) => theme.colors.primary['80']);
const CreateAccountWrapper = _styledComponents.default.div.withConfig({
  displayName: "LoginContent__CreateAccountWrapper",
  componentId: "sc-1w7wyb0-8"
})(["display:flex;align-items:center;justify-content:center;"]);
const StyledBody = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "LoginContent__StyledBody",
  componentId: "sc-1w7wyb0-9"
})(["", ";line-height:1.6rem;text-align:center;"], ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.body.small));
const StyledLink = _styledComponents.default.a.withConfig({
  displayName: "LoginContent__StyledLink",
  componentId: "sc-1w7wyb0-10"
})(["margin-left:0.4rem;font-weight:700;", ";line-height:2.4rem;color:#000000;text-underline-offset:0.4rem;text-decoration-color:", ";text-decoration-thickness:0.2rem;"], ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.body.small), ({
  theme
}) => theme.colors.neutral['20']);