"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactIntl = require("react-intl");
var _EmailConfirm = _interopRequireDefault(require("./svg/EmailConfirm.svg"));
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _ModalHeader = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/ModalHeader"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _TextButton = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/TextButton"));
var __jsx = _react.default.createElement;
const CheckEmailMessage = ({
  onTryAgain,
  brandName
}) => __jsx(_react.default.Fragment, null, __jsx(StyledModalHeader, {
  variant: "regular"
}, __jsx(_reactIntl.FormattedMessage, {
  id: "6eeBcP",
  defaultMessage: [{
    "type": 0,
    "value": "Controleer je e-mail"
  }]
})), __jsx(EmailConfirmIconWrapper, null, __jsx(_EmailConfirm.default, null)), __jsx(Description, {
  text: "center"
}, __jsx(_reactIntl.FormattedMessage, {
  id: "0Cw3Ln",
  defaultMessage: [{
    "type": 0,
    "value": "We hebben een e-mail gestuurd met de link naar de Mijn "
  }, {
    "type": 1,
    "value": "brandName"
  }, {
    "type": 0,
    "value": " omgeving. Bekijk je e-mail om in te loggen."
  }],
  values: {
    brandName
  }
})), __jsx(TryAgain, null, __jsx(_reactIntl.FormattedMessage, {
  id: "akNqjb",
  defaultMessage: [{
    "type": 0,
    "value": "Geen e-mail ontvangen? "
  }, {
    "type": 1,
    "value": "link"
  }, {
    "type": 0,
    "value": " of check je spam mailbox."
  }],
  values: {
    link: __jsx(StyledTextButton, {
      type: "submit",
      onClick: () => onTryAgain()
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "f/XZUd",
      defaultMessage: [{
        "type": 0,
        "value": "Probeer het opnieuw"
      }]
    }))
  }
})));
var _default = exports.default = CheckEmailMessage;
const StyledModalHeader = (0, _styledComponents.default)(_ModalHeader.default).withConfig({
  displayName: "CheckEmailMessage__StyledModalHeader",
  componentId: "ljitez-0"
})(["h1{", ";font-weight:700;line-height:2.8rem;color:", ";}"], ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.title.regular), ({
  theme
}) => theme.colors.primary['80']);
const EmailConfirmIconWrapper = _styledComponents.default.div.withConfig({
  displayName: "CheckEmailMessage__EmailConfirmIconWrapper",
  componentId: "ljitez-1"
})(["display:flex;justify-content:center;padding-top:2.4rem;"]);
const TryAgain = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "CheckEmailMessage__TryAgain",
  componentId: "ljitez-2"
})(["", ";text-align:center;line-height:1.6rem;color:", ";"], ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.body.small), ({
  theme
}) => theme.colors.neutral['80']);
const Description = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "CheckEmailMessage__Description",
  componentId: "ljitez-3"
})(["margin-top:", ";margin-bottom:2.5rem;max-width:", ";text-align:", ";color:", ";@media screen and (max-width:720px){max-width:100%;}"], ({
  theme
}) => theme.spacing['50_Semi'], ({
  width
}) => width ?? '100%', ({
  text
}) => text ?? 'start', ({
  theme
}) => theme.colors.neutral['80']);
const StyledTextButton = (0, _styledComponents.default)(_TextButton.default).withConfig({
  displayName: "CheckEmailMessage__StyledTextButton",
  componentId: "ljitez-4"
})(["text-decoration:underline;text-decoration-thickness:0.1rem;text-underline-offset:0.2rem;color:", ";:hover{color:", ";}span{::after{border:none;}}"], ({
  theme
}) => theme.colors.primary['80'], ({
  theme
}) => theme.colors.primary['80']);