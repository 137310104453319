"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _portal = _interopRequireDefault(require("@reach/portal"));
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var __jsx = _react.default.createElement;
const Spinner = ({
  message,
  dimmed = false
}) => __jsx(_portal.default, null, __jsx(Container, {
  dimmed: dimmed
}, __jsx(Wrapper, null, message && __jsx(_Title.default, {
  variant: "large",
  elementType: "h2"
}, message), __jsx(StyledSpinner, null))));
var _default = exports.default = Spinner;
const Wrapper = _styledComponents.default.div.withConfig({
  displayName: "Spinner__Wrapper",
  componentId: "d0kdw7-0"
})(["display:flex;flex-direction:column;align-items:center;"]);
const Container = _styledComponents.default.div.withConfig({
  displayName: "Spinner__Container",
  componentId: "d0kdw7-1"
})(["display:flex;align-items:center;justify-content:center;opacity:", ";background:", ";position:fixed !important;left:0;right:0;top:0;bottom:0;animation:fadein 100ms forwards ease-out;z-index:3;@keyframes fadein{from{opacity:0;}to{opacity:", ";}}"], ({
  dimmed
}) => dimmed ? 0.6 : 1, ({
  theme
}) => theme.colors.neutral[0], ({
  dimmed
}) => dimmed ? 0.6 : 1);
const StyledSpinner = _styledComponents.default.span.withConfig({
  displayName: "Spinner__StyledSpinner",
  componentId: "d0kdw7-2"
})(["margin-top:2rem;width:40px;height:40px;background-color:", ";border-radius:100%;animation:scaleout 1s infinite ease-in-out;transform:translate(-50%,-50%);@keyframes scaleout{0%{transform:scale(0);}100%{transform:scale(1);opacity:0;}}"], ({
  theme
}) => theme.colors.secondary[40]);