"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _client = require("@apollo/client");
var _lodash = _interopRequireDefault(require("lodash.omit"));
var _router = require("next/router");
var _react = _interopRequireWildcard(require("react"));
var _reactErrorBoundary = require("react-error-boundary");
var _reactIntl = require("react-intl");
var _Spinner = _interopRequireDefault(require("../components/designsystem/Spinner"));
var _ErrorFallback = _interopRequireDefault(require("../components/ErrorFallback"));
var _TenantContext = require("../context/TenantContext");
var _graphql = require("../generated/graphql");
var _auth = require("../utils/auth");
var _getApolloErrorType = _interopRequireDefault(require("../utils/getApolloErrorType"));
var _getStorageWithExpiry = _interopRequireDefault(require("../utils/getStorageWithExpiry"));
var _sentry = _interopRequireDefault(require("../utils/sentry"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const {
  captureException
} = (0, _sentry.default)();
const isLoginRequested = authCode => typeof authCode === 'string';
const Fallback = props => {
  const {
    0: errorHandled,
    1: setErrorHandled
  } = (0, _react.useState)(true);
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  (0, _react.useEffect)(() => {
    if (props.error) {
      props.onError(props.error, setErrorHandled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (errorHandled) {
    return __jsx(_Spinner.default, {
      message: __jsx(_reactIntl.FormattedMessage, {
        id: "aXLE6j",
        defaultMessage: [{
          "type": 0,
          "value": "Mijn "
        }, {
          "type": 1,
          "value": "island"
        }],
        values: {
          island: brandConfig.name
        }
      })
    });
  }
  return __jsx(_ErrorFallback.default, props);
};
const withAuth = (Component, options) => {
  function Enhanced(props) {
    const SpinnerComponent = options?.Spinner ?? _Spinner.default;
    const router = (0, _router.useRouter)();
    const withAuthCode = isLoginRequested(router.query.authCode);
    const client = (0, _client.useApolloClient)();
    const {
      0: loading,
      1: setLoading
    } = (0, _react.useState)(false);
    const called = (0, _react.useRef)(false);
    const succesUrl = (0, _react.useMemo)(() => options?.onSucces?.(router), [router]);
    const failUrl = (0, _react.useMemo)(() => {
      if (typeof options?.onFail === 'function') {
        return options.onFail(router);
      }
      if (typeof options?.onFail === 'boolean' && options.onFail) {
        // when deeplinking lets start with a clean state
        const [nextPath] = router.asPath.split('?');
        return {
          pathname: `/login`,
          query: {
            next: `/${router.locale}/${nextPath}`
          }
        };
      }
      return undefined;
    }, [router]);
    const authQuery = options?.authQuery ?? _graphql.MeDocument;
    const result = (0, _react.useRef)();
    const {
      brandConfig
    } = (0, _TenantContext.useTenantContext)();
    const fetch = (0, _react.useCallback)((opts = {}) =>
    // @ts-ignore
    client.query(_objectSpread({
      query: authQuery,
      fetchPolicy: 'cache-first',
      errorPolicy: 'ignore'
    }, opts)), [authQuery, client]);
    const removeAuthCode = (0, _react.useCallback)(async () => {
      await router.replace({
        pathname: router.pathname,
        query: (0, _lodash.default)(router.query, 'authCode')
      }, undefined, {
        shallow: true
      });
    }, [router]);
    const handleOnFail = (0, _react.useCallback)(async () => {
      if (failUrl === 'ignore') {
        setLoading(false);
        return;
      }
      if (failUrl) {
        await router.replace(failUrl);
      } else {
        setLoading(false);
      }
    }, [failUrl, router]);
    const handleMeCheck = (0, _react.useCallback)(async () => {
      const storageUtils = (0, _getStorageWithExpiry.default)('local');
      if (!storageUtils) {
        return;
      }
      called.current = true;
      if (withAuthCode) {
        const success = await (0, _auth.login)(router.query.authCode);
        if (success) {
          options?.onAfterLoginSuccess?.(router);
        }
        await removeAuthCode();
      }
      if (!storageUtils.getItem(_auth.ACCESS_TOKEN) && !storageUtils.getItem(_auth.REFRESH_TOKEN)) {
        if (!options?.ignoreMissingCredentials) {
          await handleOnFail();
        } else {
          setLoading(false);
        }
        return;
      }
      if (!storageUtils.getItem(_auth.ACCESS_TOKEN)) {
        const success = await (0, _auth.refresh)();
        if (!success) {
          await handleOnFail();
          return;
        }
      }
      result.current = await fetch();
      if (!result.current.data.viewer) {
        const success = await (0, _auth.refresh)();
        if (!success) {
          await handleOnFail();
          return;
        }
        result.current = await fetch({
          fetchPolicy: 'network-only'
        });
        if (!result.current.data.viewer) {
          await handleOnFail();
          return;
        }
      }
      if (result.current.data.viewer) {
        if (succesUrl) {
          await router.replace(succesUrl);
        } else {
          setLoading(false);
        }
      }
    }, [withAuthCode, fetch, router, removeAuthCode, handleOnFail, succesUrl]);
    (0, _react.useEffect)(() => {
      if (router.isReady && !called.current) {
        setLoading(true);
      }
    }, [router.isReady]);
    (0, _react.useEffect)(() => {
      if (loading && !called.current) {
        handleMeCheck();
      }
    }, [handleMeCheck, loading]);
    const showSpinner = loading || !called.current;
    const isAuthorized = !!result.current?.data.viewer;
    if (!options?.withLoadingProp && showSpinner) {
      return __jsx(SpinnerComponent, {
        message: __jsx(_reactIntl.FormattedMessage, {
          id: "aXLE6j",
          defaultMessage: [{
            "type": 0,
            "value": "Mijn "
          }, {
            "type": 1,
            "value": "island"
          }],
          values: {
            island: brandConfig.name
          }
        })
      });
    }
    return __jsx(_reactErrorBoundary.ErrorBoundary, {
      fallbackRender: errProps => __jsx(Fallback, (0, _extends2.default)({
        onError: async (error, setErrorHandled) => {
          captureException(error);
          const errorType = (0, _getApolloErrorType.default)(error) ?? '500';
          if (['401'].includes(errorType)) {
            const success = await (0, _auth.refresh)();
            if (!success) {
              (0, _auth.logout)();
              await client.resetStore();
              if (failUrl) {
                router.replace(failUrl);
                return;
              }
            }
            errProps.resetErrorBoundary();
          } else {
            setErrorHandled(false);
          }
        }
      }, errProps))
    }, __jsx(Component, (0, _extends2.default)({}, props, {
      loading: showSpinner,
      isAuthorized: isAuthorized
    })));
  }
  Enhanced.displayName = `WithAuthComponent`;
  return Enhanced;
};
var _default = exports.default = withAuth;